<ng-container *ngIf="IsNullOrUndefined(tenant)">
  <div fxLayout="row" fxLayoutAlign="center center" style="min-height: 80vh;">
    <div class="container" fxLayoutAlign="center center">
      <div>
        <!-- FORM -->
        <div class="items-center content-center">
          <img class="hypecast-logo" src="../../assets/hypecast-logo.png" style="height: 110px;"/>
          <h2 class="loading"><strong>Loading</strong></h2>
        </div>
        <div class="spinner" style="height: 298px; display: flex; justify-content: center;">
          <mat-spinner [diameter]="25"></mat-spinner>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<router-outlet *ngIf="!IsNullOrUndefined(tenant)"></router-outlet>
