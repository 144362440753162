import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { LoadingBarService } from '@app/shared/services/local/loading-bar/loading-bar.service';
import { Router } from '@angular/router';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(private loadingBarService: LoadingBarService, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loadingBarService.start();
    return next.handle(request.clone()).pipe(
      takeUntil(this.router.events),
      finalize(() => {
        setTimeout(() => {
          this.loadingBarService.complete();
        }, 200);
      })
    );
  }
}
