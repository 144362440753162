import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { Observable, Subject, Subscriber, tap } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { BaseService } from "../base/services";
import { __ } from "../functions/object.functions";
import { Tenant } from "../models/classes/Tenant";
import { ITenantService } from "./itenant.service";
import { Response } from "./local/Response";
import * as moment from "moment";
import { ApiUrlService } from "@app/shared/services/api-url.service";

const tenant_key = "hypecast-tenant-l45p9non3o8dgqjerkx";

@Injectable()
export class TenantService extends BaseService implements ITenantService {
  tenant$: Observable<Tenant>;

  tenant: Tenant;

  private _tenant$: Subject<Tenant> = new Subject<Tenant>();

  // tslint:disable-next-line:member-ordering
  updateTenant$: Observable<Tenant> = this._tenant$.asObservable();

  constructor(protected httpClient: HttpClient, private apiUrlService: ApiUrlService) {
    super();

    this.tenant$ = new Observable<Tenant>((subscriber: Subscriber<Tenant>) => {
      if (!__.IsNullOrUndefined(this.tenant)) {
        subscriber.next(this.tenant);
        subscriber.complete();
      } else {
        const subdomain = this.getTenantName();

        const tenant = JSON.parse(localStorage.getItem(tenant_key)) as {
          tenant: Tenant;
          subdomain: string;
          expiresAt: string;
        };

        if (
          !__.IsNullOrUndefined(tenant) &&
          tenant.subdomain === subdomain &&
          !__.IsNullOrUndefined(tenant.expiresAt) &&
          moment(tenant.expiresAt) > moment()
        ) {
          this._tenant$.next(tenant.tenant);
          this.tenant = tenant.tenant;
          subscriber.next(this.tenant);
          subscriber.complete();
        } else {
          if (environment.environmentName === "DEV") {
            const localTenant = Object.assign(new Tenant(), {
              tenantName: environment.subdomain,
              apiUrl: environment.serverUrl,
              cdnUrl: environment.serverUrlCDN,
              clientId: environment.clientId,
              companyName: "OMR",
              logoUrl: "https://i0.wp.com/hypecast.one/wp-content/uploads/2021/06/white-1.png?fit=582%2C135&ssl=1",
            } as Tenant);

            this.tenant = localTenant;
            subscriber.next(localTenant);
            subscriber.complete();
          } else {
            super.addSubscription(
              this.httpClient
                .disableApiPrefix()
                .disableAccessToken()
                .disableAuthorizationInterceptor()
                .get(`${environment.masterServerUrl}tenants/${subdomain}`)
                .pipe(
                  map((response: Response<Tenant>) => {
                    localStorage.setItem(
                      tenant_key,
                      JSON.stringify({
                        tenant: response.data,
                        subdomain,
                        expiresAt: moment().add(1, "day"),
                      })
                    );

                    this.tenant = response.data;
                    this._tenant$.next(response.data);
                    return response.data;
                  }),
                  catchError((error: HttpErrorResponse) => {
                    throw error;
                  })
                )
                .subscribe({
                  next: (fetchedTenant: Tenant) => {
                    this.tenant = fetchedTenant;
                    subscriber.next(this.tenant);
                    subscriber.complete();
                  },
                })
            );
          }
        }
      }
    }).pipe(tap((tenant) => (this.apiUrlService.getTenant = () => tenant)));
  }

  getTenant$(): Observable<Tenant> {
    return this.tenant$;
  }

  getUpdateTenant$(): Observable<Tenant> {
    return this.updateTenant$;
  }

  getTenant(): Tenant {
    return this.tenant;
  }

  getTenantName(): string {
    if (environment.environmentName === "DEV" && !__.IsNullOrUndefined(environment.subdomain)) {
      return environment.subdomain;
    }

    const domain = window.location.hostname;

    const parts = domain.split(".");

    return parts[1];
  }

  setLogo(logo: string) {
    this.tenant.logoUrl = logo;

    let tenant = JSON.parse(localStorage.getItem(tenant_key)) as { tenant: Tenant; subdomain: string };

    if (!__.IsNullOrUndefinedOrEmpty(tenant)) {
      tenant.tenant.logoUrl = logo;
    } else {
      // Only happens locally
      tenant = Object.assign(
        {},
        {
          tenant: Object.assign(
            {},
            {
              tenantName: this.tenant.tenantName,
              logoUrl: logo,
              cdnUrl: this.tenant.cdnUrl,
              apiUrl: this.tenant.apiUrl,
              clientId: this.tenant.clientId,
              companyName: this.tenant.companyName,
            }
          ),
          subdomain: this.getTenantName(),
        }
      );
    }

    localStorage.setItem(
      tenant_key,
      JSON.stringify({
        tenant: tenant.tenant,
        subdomain: tenant.subdomain,
      })
    );

    this._tenant$.next(tenant.tenant);
  }

  uploadTenantLogo(file: File): Observable<string> {
    const formData = new FormData();
    formData.append("file", file);

    const subdomain = this.getTenantName();

    return this.httpClient
      .skipErrorHandler()
      .disableApiPrefix()
      .disableAuthorizationInterceptor()
      .post(`${environment.authorizationServerUrl}api/v1/tenants/${subdomain}/logo`, formData)
      .pipe(
        map((response: Response<string>) => {
          return response.data;
        })
      );
  }

  // Removes the tenant from the local storage
  resetTenant(): void {
    sessionStorage.removeItem(tenant_key);
    localStorage.removeItem(tenant_key);
  }
}
