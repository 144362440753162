export const environment = {
  production: false,
  testPermissions: true,
  serverUrl: 'https://api.test-tenant-30.hypecast-0.dev/api/v1/', // not used due to api-url.service.ts
  serverUrlCDN: 'https://api.test-tenant-30.hypecast-0.dev/api/v1/', // not used due to api-url.service.ts
  clientId: '53CD0CA752D0408283812278B8FF2248',
  masterServerUrl: 'https://master.hypecast.dev/api/v1/',
  authorizationServerUrl: 'https://id.hypecast.dev/',
  agora: {
    appId: 'c210e8462c5442bcb6a6c55a69a76352',
  },
  defaultLanguage: {locale: 'en-US', name: 'English'},
  environmentName: 'QA',
  supportedLanguages: [
    {locale: 'en-US', name: 'English'},
    {locale: 'de-DE', name: 'Deutsch'},
  ],
  subdomain: '',
  hmr: false
};
