/*
 * Entry point of the application.
 * Only platform bootstrapping code should be here.
 * For app-specific initialization, use `app/app.component.ts`.
 */

import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "@app/app.module";
import { environment } from "@env/environment";
import { hmrBootstrap } from "./hmr";
import { datadogLogs } from "@datadog/browser-logs";

if (environment.production) {
  enableProdMode();
  datadogLogs.init({
    clientToken: "puba04ca3df02f8a665c25814136dbf4d42",
    site: "datadoghq.eu",
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    service: "Studio",
    env: "hypecast.one",
  });
}

if (environment.environmentName === "QA") {
  datadogLogs.init({
    clientToken: "puba04ca3df02f8a665c25814136dbf4d42",
    site: "datadoghq.eu",
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    service: "Studio",
    env: "hypecast.dev",
  });
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
  hmrBootstrap(module, bootstrap);
} else {
  bootstrap().catch((err) => console.error(err));
}
