import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppRoute } from './shared/app.route.enum';

const routes: Routes = [
  {
    path: AppRoute.Meeting,
    loadChildren: () => import('./studio/studio.module').then(m => m.StudioModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
