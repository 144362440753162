import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { ApiUrlService } from '@app/shared/services/api-url.service';
import { environment } from '@env/environment';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { Logger } from '../logger.service';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private injector: Injector,
    private toastr: ToastrService,
    private apiUrlService: ApiUrlService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(0),
      catchError(error => this.errorHandler(request, error, next))
    );
  }

  private errorHandler(
    request: HttpRequest<any>,
    error: HttpErrorResponse,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request.url.includes(this.apiUrlService.getApiUrl()) ||
      request.url.replace(/\/api\/v\d+/, '').includes(this.apiUrlService.getApiUrl())
    ) {
      if (error.status === 0) {
        this.inaccessible();
      } else {
        if (error.status === 1001) {
          this.validationError(error);
        } else {
          this.internalServerError(error);
        }
      }
    } else {
      this.toastr.error(
        'An error that should not happened has happened. Sorry for that. Please contact the administrator'
      );
    }
    // TODO: LOG THE ERRORS
    if (!environment.production) {
      log.error('Request error', error);
    }
    throw error;
  }

  private inaccessible() {
    let message = 'The api server is not accessible';
    if (!environment.production) {
      message = message + '. (The webapi server is down)';
    }
    this.toastr.error(message);
  }

  private internalServerError(error: any) {
    if (error.status === 500) {
      // todo: add logging
      this.toastr.error(
        'An error that should not happened has happened. Sorry for that. Please contact the administrator of your company'
      );
    }
  }

  private validationError(error: any) {
    if (error.error.code.internalCode === 1001) {
      this.toastr.error('A validation error occured. Please correct your input');
    }
  }
}
