import { ModuleWithProviders, NgModule } from '@angular/core';

import { createDefaultLogger, createDefaultStorage } from './angular-oauth-oidc/factories';
import {
    OAuthNoopResourceServerErrorHandler,
    OAuthResourceServerErrorHandler,
} from './angular-oauth-oidc/interceptors/resource-server-error-handler';
import { OAuthService } from './angular-oauth-oidc/oauth-service';
import { DefaultHashHandler, HashHandler } from './angular-oauth-oidc/token-validation/hash-handler';
import { NullValidationHandler } from './angular-oauth-oidc/token-validation/null-validation-handler';
import { ValidationHandler } from './angular-oauth-oidc/token-validation/validation-handler';
import { OAuthLogger, OAuthStorage } from './angular-oauth-oidc/types';
import { UrlHelperService } from './angular-oauth-oidc/url-helper.service';
import { HypecastCredentialsAuthenticationGuard } from './guards/hypecast-credentials-auth.guard';
import { HypecastAuthenticationService } from './services/authentication.service';
import { HypecastOAuthService } from './services/hypecast-oauth.service';

@NgModule()
export class HypecastAuthenticationModule {
    static forRoot(
        validationHandlerClass = NullValidationHandler
    ): ModuleWithProviders<HypecastAuthenticationModule> {
        return {
            ngModule: HypecastAuthenticationModule,
            providers: [
                OAuthService,
                UrlHelperService,
                HypecastOAuthService,
                HypecastCredentialsAuthenticationGuard,
                HypecastAuthenticationService,
                { provide: OAuthLogger, useFactory: createDefaultLogger },
                { provide: OAuthStorage, useFactory: createDefaultStorage },
                { provide: ValidationHandler, useClass: validationHandlerClass },
                { provide: HashHandler, useClass: DefaultHashHandler },
                {
                    provide: OAuthResourceServerErrorHandler,
                    useClass: OAuthNoopResourceServerErrorHandler
                }
            ]
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ CONSTRUCTOR
    // -----------------------------------------------------------------------------------------------------

    constructor(private oauthService: HypecastOAuthService) {
    }
}
