import { __ } from '@app/shared/functions/object.functions';
import { environment } from '@env/environment';
import { HypecastOAuthConfig } from './hypecast-oauth-config';

export class HypecastBaseOAuthConfig extends HypecastOAuthConfig {

    // -----------------------------------------------------------------------------------------------------
    // @ CONSTRUCTOR
    // -----------------------------------------------------------------------------------------------------
    constructor(scope?: string, issuer?: string, issuerConfig?: string, redirectUri?: string, responseType?: string) {
        super();

        this.scope = __.IsNullOrUndefined(scope) ? ' offline_access' : scope;
        // this.issuer = __.IsNullOrUndefined(scope) ? 'https://localhost:5007/' : issuer;
        this.issuer = __.IsNullOrUndefined(issuer) ? environment.authorizationServerUrl : issuer;
        this.redirectUri = __.IsNullOrUndefined(redirectUri) ? window.location.origin : redirectUri;
        this.responseType = __.IsNullOrUndefined(responseType) ? 'code' : responseType;
        this.strictDiscoveryDocumentValidation = false;
    }
}
